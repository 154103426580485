import PropTypes from "prop-types";
import { useState } from "react";

import classNames from "classnames";
import cn from "./Feedback.module.css";

function Feedback({ done = () => {}, kind = "lesson" }) {
  const [hasSubmitted, setSubmitted] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [score, setScore] = useState(null);
  const [comment, setComment] = useState(null);

  const buttons = [
    {
      score: 5,
      emoji: "love",
      label: "Loved it!",
    },
    {
      score: 3,
      emoji: "good",
      label: "Pretty good",
    },
    {
      score: 2,
      emoji: "decent",
      label: "Could be improved",
    },
    {
      score: 1,
      emoji: "bad",
      label: "I didn't like it",
    },
  ];

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleSubmit = async () => {
    if (hasSubmitted) {
      return;
    }

    setLoading(true);
    const { token } = window.CSSMasterClass;

    const csrfToken = document.querySelector("meta[name='csrf-token']");

    if (!csrfToken) {
      throw new Error("No token available.");
    }

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken.content,
      },
      credentials: "include", // Include cookies in the request,
      body: JSON.stringify({
        token,
        score,
        comment,
      }),
    };

    try {
      const response = await fetch(`/api/feedback/${kind}`, options);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      setSubmitted(true);
      done();
    } catch (error) {
      console.error("Error fetching data: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const componentCN = classNames({
    [cn.component]: true,
    [cn["submitted"]]: hasSubmitted,
  });

  return (
    <div className={componentCN}>
      <div className={cn.main}>
        <p>Great job! How did you find this {kind}?</p>

        <p className={cn.buttons}>
          {buttons.map((item) => {
            const cn = classNames({
              [`button`]: true,
              [`is-outlined`]: score !== item.score,
              [`is-primary`]: score === item.score,
            });

            return (
              <button
                className={cn}
                key={item.label}
                onClick={() => setScore(item.score)}
              >
                <span className="button-label">
                  <span className={`emoji is-${item.emoji}`} />
                  {item.label}
                </span>
              </button>
            );
          })}
        </p>

        <textarea
          className="form-control"
          value={comment}
          onChange={handleCommentChange}
          placeholder="Any additional comment?"
        />

        <div className={cn.submit}>
          <button
            className="button is-primary"
            onClick={handleSubmit}
            disabled={isLoading || (!score && !comment)}
          >
            <span className="button-label">Submit Feedback</span>
          </button>
        </div>
      </div>

      <div className={cn.thanks}>
        Thank you for your feedback! It will help improve this {kind}.
      </div>
    </div>
  );
}

Feedback.propTypes = {
  done: PropTypes.func,
  kind: PropTypes.string,
};

export default Feedback;
